// 软件信息管理-详情
<template>
    <div class="system-detail baseBg">
        <backUp v-if="showBack"></backUp>
        <div class="searchBar ofh flex-between-center">
            <h3 style="margin: 0;">{{systemInfo.name || ''}}</h3>
            <el-button type="success"
                       @click="addNew">新建版本</el-button>
        </div>
        <div class="listBox">
            <div class="tableBox">
                <el-table :data="tableData"
                          :height="tableHeight"
                          v-loading="isloading"
                          border
                          stripe>
                    <el-table-column v-for="col in tableColumns"
                                     :prop="col.prop"
                                     :key="col.label"
                                     :label="col.label"
                                     :min-width="col.width">
                    </el-table-column>
                    <el-table-column label="操作"
                                     min-width="100">
                        <template slot-scope="scope">
                            <el-link type="primary"
                                     @click="seeDetail(scope.row)">查看详情</el-link>
                            <el-link type="primary"
                                     @click="editTable(scope.row)">编辑</el-link>
                            <el-popconfirm confirmButtonText='确认'
                                           cancelButtonText='取消'
                                           icon="el-icon-info"
                                           iconColor="red"
                                           @confirm="deleteTable(scope.row)"
                                           title="确定删除吗？">
                                <el-link slot="reference"
                                         type='danger'>删除</el-link>
                            </el-popconfirm>
                        </template>
                    </el-table-column>
                </el-table>
                <!-- 分页 -->
                <Pagination :page="searchForm.page"
                            :limit="searchForm.size"
                            :total="pageTotal"
                            @pagination="paginationChange" />
            </div>
        </div>
        <system-version-dialog :title="dialogForm ? '编辑' : '新增'"
                               :form-data="dialogForm"
                               :systemId="systemInfo.id"
                               v-if="dialogVisible"
                               @close="closeDialog"
                               @refresh="search" />
    </div>
</template>

<script>
import baseManageApi from "@/api/baseManageApi.js";
import Pagination from "@/components/Pagination"; // 分页
import systemVersionDialog from "./components/pop_systemVersionDialog";
import backUp from "@/components/backUp";
export default {
    name: "systemDetail",
    props: [],
    components: {
        Pagination,
        systemVersionDialog,
        backUp,
    },

    data() {
        return {
            systemInfo: {},
            dialogVisible: false,
            dialogForm: null,
            searchForm: {
                page: 1,
                size: 20,
            },
            pageTotal: 0,
            isloading: false,
            tableColumns: [
                {
                    prop: "name",
                    label: "版本名称",
                    width: "100",
                },
                {
                    prop: "customerNames",
                    label: "使用地点",
                    width: "100",
                },
                {
                    prop: "systemUrl",
                    label: "系统地址",
                    width: "100",
                },
                {
                    prop: "memo",
                    label: "备注",
                    width: "100",
                },
            ],
            tableData: [],
            showBack:true,//返回上级页面
        };
    },

    created() {},

    mounted() {
        this.systemInfo = JSON.parse(this.$route.query.systemInfo);
        this.showBack = this.systemInfo.showBack === false ? false : true;
        this.search();
    },

    methods: {
        /* 获取列表 */
        getList(searchForm = this.searchForm) {
            this.isloading = true;
            baseManageApi
                .getSoftVersionList(
                    Object.assign(searchForm, { systemId: this.systemInfo.id })
                )
                .then((res) => {
                    this.isloading = false;
                    this.tableData = res.content.records;
                    this.pageTotal = res.content.total;
                })
                .catch(() => {
                    this.isloading = false;
                });
        },
        // 搜索
        search() {
            this.searchForm.page = 1;
            this.getList();
        },
        // 新增
        addNew() {
            this.dialogForm = null;
            this.dialogVisible = true;
        },
        // 分页改变
        paginationChange(e) {
            this.getList(Object.assign(this.searchForm, e));
        },
        // 新增或編輯彈框 关闭
        closeDialog() {
            this.dialogVisible = false;
        },
        // 表格的编辑操作
        editTable(e) {
            this.dialogVisible = true;
            this.dialogForm = JSON.parse(JSON.stringify(e));
        },
        // 表格的删除操作
        deleteTable(row) {
            this.isloading = true;
            baseManageApi
                .delSoftVersion({ id: row.id })
                .then(() => {
                    this.$message({
                        message: "删除成功",
                        type: "success",
                    });
                    this.getList();
                })
                .catch(() => {
                    this.isloading = false;
                });
        },

        //查看详情
        seeDetail(e) {
            this.$router.push({
                path: "/baseInfoManage/comSoftwareDetail",
                query: {
                    data: JSON.stringify(
                        Object.assign({}, e, {
                            // empTypeId: this.deviceTypeInfo.id,
                            // empType: this.deviceTypeInfo.empType,
                        })
                    ),
                },
            });
        },
    },

    computed: {
        tableHeight() {
            return this.commonJs.getTableHeight({ pageOtherHeight: 270 });
        },
    },

    watch: {},
};
</script>

<style lang='scss' scoped>
</style>
